import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const db = getFirestore();
const auth = getAuth();

async function fetchJobsFromFirestore(queryConstraints) {
  if (!Array.isArray(queryConstraints)) {
    throw new Error("queryConstraints must be an array");
  }

  const q = query(collection(db, "jobs"), ...queryConstraints);
  const querySnapshot = await getDocs(q);

  const jobs = [];
  querySnapshot.forEach((doc) => {
    jobs.push({
      id: doc.id,
      name: doc.data().name,
      account: doc.data().account,
      billing_date: doc.data().billing_date,
      description: doc.data().description || '', // description フィールドを追加
    });
  });

  const groupedJobs = jobs.reduce((groups, job) => {
    if (!groups[job.account]) {
      groups[job.account] = [];
    }
    groups[job.account].push({
      value: job.id,
      label: job.name + (job.description ? job.description : ''),
    });
    return groups;
  }, {});

  const additionalOptions = {
    "株式会社アーキタイプ": [
      { value: "ARCHETYP_Internal", label: "社内業務" },
      { value: "UNKNOWN_PROJECT", label: "登録されていない案件" },
    ],
  };

  // 「株式会社アーキタイプ」が取得データにある場合
  if (groupedJobs["株式会社アーキタイプ"]) {
    // 既存のデータに追加するが、常に先頭に配置する
    groupedJobs["株式会社アーキタイプ"] = [
      ...additionalOptions["株式会社アーキタイプ"],
      ...groupedJobs["株式会社アーキタイプ"],
    ];
  } else {
    // 存在しない場合、新しく追加
    groupedJobs["株式会社アーキタイプ"] = additionalOptions["株式会社アーキタイプ"];
  }

  // 「株式会社アーキタイプ」を先頭に持ってくるためにオブジェクトを並べ替える
  const sortedGroupedJobs = { "株式会社アーキタイプ": groupedJobs["株式会社アーキタイプ"], ...groupedJobs };

  return sortedGroupedJobs;
}



async function fetchJobs(cacheKey, queryConstraints) {
  if (!cacheKey || !queryConstraints) {
    throw new Error("Both cacheKey and queryConstraints are required");
  }

  const cachedData = sessionStorage.getItem(cacheKey);
  const cacheTimestamp = sessionStorage.getItem(`${cacheKey}_timestamp`);
  const currentTime = new Date().getTime();

  if (cachedData && cacheTimestamp && (currentTime - cacheTimestamp) < 5 * 60 * 1000) {
    return JSON.parse(cachedData);
  }

  const jobsData = await fetchJobsFromFirestore(queryConstraints);
  
  sessionStorage.setItem(cacheKey, JSON.stringify(jobsData));
  sessionStorage.setItem(`${cacheKey}_timestamp`, currentTime);

  return jobsData;
}


export { fetchJobs };
