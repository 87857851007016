import React, { useState, useCallback, useEffect } from 'react';
import { Calendar, Clock } from 'lucide-react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import FilterableSelect from './FilterableSelect';
import { collection, doc, getDocs, query, where, orderBy, writeBatch, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase-config';
import { fetchJobs } from "../services/fetchJobs";

const RegisterView = () => {
  const navigate = useNavigate();
  const [registerEntries, setRegisterEntries] = useState([{ time: '', job_id: '', description: '' }]);
  const [registerDate, setRegisterDate] = useState('');
  const [taskOptions, setTaskOptions] = useState([]);
  const [errors, setErrors] = useState({});

  const fetchJobsData = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) {
      navigate("/");
      return;
    }
    
    const queryConstraints1 = [
      where("isDeleted", "==", false),
      where("users", "array-contains", user.email),
      where("stage", "in", ["07-ブック（受注）", "06-セキュア（契約合意）", "05-リスク（リスクの排除）", "04-アップサイド③（意思決定者の賛同）"]),
      where("billing_status", "!=", "請求済み"),
      orderBy("billing_status"),
      orderBy("account"),
      orderBy("billing_date"),
      orderBy("name"),
    ];

    const queryConstraints2 = [
      where("isDeleted", "==", false),
      where("users", "array-contains", user.email),
      where("stage", "in", ["07-ブック（受注）", "06-セキュア（契約合意）", "05-リスク（リスクの排除）", "04-アップサイド③（意思決定者の賛同）"]),
      where("billing_status", "==", null),
      orderBy("account"),
      orderBy("billing_date"),
      orderBy("name"),
    ];
    
    try {
      const taskOptions1 = await fetchJobs("registerViewJobs1", queryConstraints1);
      const taskOptions2 = await fetchJobs("registerViewJobs2", queryConstraints2);

      // マージ用の空オブジェクトを用意
      const mergedTaskOptions = {};

      // taskOptions1 をマージ
      Object.keys(taskOptions1).forEach(account => {
        if (!mergedTaskOptions[account]) {
          mergedTaskOptions[account] = [];
        }
        mergedTaskOptions[account] = mergedTaskOptions[account].concat(taskOptions1[account]);
      });

      // taskOptions2 をマージ
      Object.keys(taskOptions2).forEach(account => {
        if (!mergedTaskOptions[account]) {
          mergedTaskOptions[account] = [];
        }
        // 重複を避けるために `value` をキーにして存在チェック
        const existingValues = new Set(mergedTaskOptions[account].map(item => item.value));
        taskOptions2[account].forEach(item => {
          if (!existingValues.has(item.value)) {
            mergedTaskOptions[account].push(item);
          }
        });
      });

      // 最終的なオプション形式に変換
      setTaskOptions(Object.keys(mergedTaskOptions).map((account) => ({
        label: account,
        options: mergedTaskOptions[account],
      })));
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  }, [navigate]);
  

  useEffect(() => {
    fetchJobsData();
  }, [fetchJobsData]);

  const handleBackToList = () => {
    navigate('/list');
  };

  const addWorkEntry = useCallback(() => {
    setRegisterEntries(prev => [...prev, { time: '', job_id: '', description: '' }]);
  }, []);

  const updateWorkEntry = useCallback((index, field, value) => {
    setRegisterEntries(prev => {
      const newEntries = [...prev];
      newEntries[index] = { ...newEntries[index], [field]: value };
      return newEntries;
    });
  }, []);

  const removeWorkEntry = useCallback((index) => {
    setRegisterEntries(prev => prev.filter((_, i) => i !== index));
  }, []);

  const fetchAndLogWorks = async (userId) => {
    const worksCollection = collection(db, `users/${userId}/works`);
    const worksSnapshot = await getDocs(worksCollection);
    worksSnapshot.forEach(doc => {
      console.log(doc.id, " => ", doc.data());
    });
  };

  const handleSubmit = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/');
      return;
    }

    const newErrors = {};
    if (!registerDate) {
      newErrors.registerDate = '日付を入力してください';
    }
    registerEntries.forEach((entry, index) => {
      if (!entry.job_id) {
        newErrors[`job_id_${index}`] = '業務を選択してください';
      }
      if (!entry.time) {
        newErrors[`time_${index}`] = '稼働時間を入力してください';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const batch = writeBatch(db);
  
    try {
      console.log('Starting batch operation');
      registerEntries.forEach(entry => {
        const workRef = doc(collection(db, 'works'));
        const job = taskOptions.flatMap(group => group.options).find(task => task.value === entry.job_id);
        const jobName = job ? job.label : '';
        console.log('Adding entry to batch:', {
          user_id: user.uid,
          job_id: entry.job_id,
          job_name: jobName,
          time: entry.time,
          description: entry.description,
          date: registerDate,
          created_at: serverTimestamp(),
          modified_at: serverTimestamp(),
        });
        batch.set(workRef, {
          user_id: user.uid,
          job_id: entry.job_id,
          job_name: jobName,
          time: entry.time,
          description: entry.description,
          date: registerDate,
          created_at: serverTimestamp(),
          modified_at: serverTimestamp(),
        });
      });
  
      await batch.commit();
      console.log('Batch operation completed');
  
      setRegisterEntries([{ time: '', job_id: '', description: '' }]);
      setRegisterDate('');
      setErrors({});
      navigate('/list');
    } catch (error) {
      console.error('Error submitting entries:', error);
    }
  }, [registerEntries, registerDate, navigate, taskOptions]);

  const insertPresetText = useCallback((text, index) => {
    updateWorkEntry(index, 'description', registerEntries[index].description + text + '\n');
  }, [updateWorkEntry, registerEntries]);

  const presetTags = [
    "MTG",
    "メール・チャット・電話等",
    "見積り・契約書作成・請求等",
    "調査・リサーチ",
    "資料・提案書作成",
    "デザイン",
    "実装・開発",
    "保守・運用",
    "進行管理"
  ];

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">稼働登録</h2>
        <Button
          onClick={handleBackToList}
          className="bg-primary text-white hover:bg-primary-dark"
        >
          一覧に戻る
        </Button>
      </div>
      <div className="w-1/2 mx-auto min-w-96 space-y-4">
        <div className="mb-4">
          <div className="flex items-center space-x-2">
            <Calendar className="text-gray-400" />
            <Input 
              type="date" 
              className="flex-grow"
              value={registerDate}
              onChange={(e) => setRegisterDate(e.target.value)}
            />
          </div>
          {errors.registerDate && <div className="text-red-500 mt-1">{errors.registerDate}</div>}
        </div>
        {registerEntries.map((entry, index) => (
          <div key={index} className="p-4 border border-gray-200 rounded-md space-y-2">
            <FilterableSelect
              options={taskOptions}
              value={entry.job_id}
              onChange={(value) => updateWorkEntry(index, 'job_id', value)}
              placeholder="業務を選択"
            />
            {errors[`job_id_${index}`] && <div className="text-red-500 mt-1">{errors[`job_id_${index}`]}</div>}
            <div className="flex items-center space-x-2">
              <Clock className="text-gray-400" />
              <Input
                type="number"
                placeholder="稼働時間"
                min="0"
                step="0.25"
                value={entry.time}
                onChange={(e) => updateWorkEntry(index, 'time', e.target.value)}
                className="flex-grow"
              />
            </div>
            {errors[`time_${index}`] && <div className="text-red-500 mt-1">{errors[`time_${index}`]}</div>}
            <div className="flex flex-wrap gap-2 mt-2">
              {presetTags.map(tag => (
                <span
                key={tag}
                className="tag bg-gray-100 text-gray-800 px-2 py-1 rounded-full cursor-pointer text-sm hover:bg-gray-200" // hover効果を修正
                onClick={() => insertPresetText(tag, index)}
              >
                {tag}
              </span>
              ))}
            </div>
            <textarea
              className="w-full h-20 p-2 border rounded"
              placeholder="詳細な作業内容を入力してください"
              value={entry.description}
              onChange={(e) => updateWorkEntry(index, 'description', e.target.value)}
            ></textarea>
            {registerEntries.length > 1 && (
              <Button
                type="button"
                onClick={() => removeWorkEntry(index)}
                className="mt-2 bg-red-500 hover:bg-red-600 text-white"
              >
                削除
              </Button>
            )}
          </div>
        ))}
        <Button type="button" onClick={addWorkEntry} className="w-full bg-green-500 hover:bg-green-600 text-white">
          業務を追加
        </Button>
        <Button onClick={handleSubmit} className="w-full bg-primary hover:bg-primary-dark text-white">
          登録
        </Button>
      </div>
    </div>
  );
};

export default RegisterView;
